.select-container {
  width: 100%; }

.select {
  + .ui-selectmenu-button {
    border: 3px solid $black;
    border-radius: 0;
    padding: 10px 15px;
    background-color: $white;
    @include os-semi;
    color: $black;
    vertical-align: middle;
    &.ui-button {
      width: 100%; }
    .ui-selectmenu-icon.ui-icon {
      margin-top: 3px; } }
  &_border-tr {
    + .ui-selectmenu-button {
      border-color: #f0f0f0;
      &.ui-selectmenu-button-open {
        border-color: $black; } } }
  &_grey {
    + .ui-selectmenu-button {
      background-color: #f0f0f0; } }

  + .ui-button {
    .ui-icon {
      background-image: url(../img/select-arrow.png);
      @include contain; }
    &:hover {
      .ui-icon {
        background-image: url(../img/select-arrow.png); } }
    &:focus {
      .ui-icon {
        background-image: url(../img/select-arrow.png); } } } }

.ui-widget {
  @include os-semi;
  &.ui-widget-content {
    border: 3px solid $black;
    border-top: none;
    width: 100% !important; } }

.ui-menu .ui-menu-item-wrapper {
  padding: 15px;
  font-size: 14px;
  @include os-semi;
  @include rmin(768) {
    font-size: 18px; } }

.ui-selectmenu-menu {
  width: 100%;
  text-align: left; }
