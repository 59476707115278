.customers-slide {
  &.slick-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -22.5px;
    @include rmin(1366) {
      flex-wrap: nowrap; } }
  &__item {
    width: 27%;
    filter: grayscale(100%);
    opacity: .5;
    transition: filter .3s ease, opacity .3s ease;
    margin: 0 22.5px;
    margin-bottom: 25px;
    @include rmin(1366) {
      margin-bottom: 0;
      width: 15%; }
    &:hover {
      filter: none;
      opacity: 1; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } } }

.customers-slider {
  position: relative;
  padding-top: 25px;
  .slick-arrow {
    background-color: transparent;
    font-size: 0;
    width: 30px;
    height: 25px;
    position: absolute;
    top: 0;
    @include contain;
    transition: background .3s ease;
    &.slick-prev {
      background-image: url(../img/arrow-left.svg);
      left: 0;
      @include rmin(768) {
        left: auto;
        right: 60px; }
      &:hover:not(.slick-disabled) {
        background-image: url(../img/arrow-left-blue.svg); } }
    &.slick-next {
      background-image: url(../img/arrow-right.svg);
      right: 0;
      &:hover:not(.slick-disabled) {
        background-image: url(../img/arrow-right-blue.svg); } }
    &.slick-disabled {
      opacity: .5; } } }
