.contacts-item-mb {
  margin-bottom: 30px;
  position: relative; }

.contacts-block-wrap {
  display: flex;
  flex-wrap: wrap; }

.contacts-block {
  padding-top: 30px;
  width: 100%;
  @include rmin(768) {
    width: 50%;
    min-height: 200px; }
  &__logo {
    display: inline-block;
    vertical-align: top;
    @include rmin(768) {
      display: block;
      min-height: 48px;
      margin-bottom: 25px; }
    .icon-location,
    .icon-phone,
    .icon-open,
    .icon-email {
      fill: #e01b22; }
    .icon-location {
      font-size: 28px;
      @include rmin(768) {
        font-size: 48px; } }
    .icon-phone {
      font-size: 21px;
      @include rmin(768) {
        font-size: 38px; } }
    .icon-open {
      font-size: 25px;
      @include rmin(768) {
        font-size: 44px; } }
    .icon-email {
      font-size: 21px;
      @include rmin(768) {
        font-size: 32px; } } }
  &__info {
    @include os-semi;
    line-height: 1.44;
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    @include rmin(768) {
      display: block;
      padding-left: 0; } } }
