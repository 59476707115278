.guarantee {
  display: flex;
  margin-top: 40px;
  align-items: center;
  color: $white;
  &__title {
    @include os-extra;
    font-size: 18px;
    line-height: .8em;
    position: relative;
    padding-left: 45px;
    @include rmin(768) {
      font-size: 30px;
      padding-left: 75px; } }
  &__text {
    margin-left: 15px;
    margin-right: 15px;
    @include rmin(768) {
      margin-left: 35px;
      margin-right: 35px; } }
  &__icon {
    position: absolute;
    left: 0;
    top: -10px;
    font-size: 40px;
    @include rmin(768) {
      font-size: 65px; }
    .icon-calendar,
    .icon-dashboard {
      fill: #fff; } } }
