.application-wrap {
  padding: 1px;
  background-color: $white;
  background-image: url(../img/application-bg.png);
  @include cover; }

.application {
  text-align: center;
  max-width: 740px;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
  @include rmin(768) {
    padding: 0 0 70px; }
  &__text {
    margin-bottom: 15px; } }
