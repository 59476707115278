.grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  @include rmin(1024) {
    &_reverse {
      flex-direction: row-reverse;
      .grid-item {
        &__content {
          padding: 30px 2vw 50px 30px;
          @include rmin(1366) {
            padding: 30px 2vw 50px 50px; }
          @include rmin(1600) {
            padding-right: 8vw; }
          @include rmin(1920) {
            padding-right: 13vw; } }
        &__pic-content {
          padding: 25px 25px 25px 2vw;
          &_l {
            padding: 55px 55px 55px 2vw; }
          @include rmin(1600) {
            padding-left: 8vw; }
          @include rmin(1920) {
            padding-left: 13vw; } } } } }
  &_mt {
    padding-top: 20px;
    @include rmin(1024) {
      padding-top: 90px; } }
  &_bg {
    background-color: $white;
    background-image: url(../img/application-bg.png);
    @include cover; } }

.grid-item {
  width: 100%;
  position: relative;
  @include rmin(1024) {
    width: 50%; }
  &_blue {
    background-color: $accent; }
  &_gallery {
    min-height: 180px;
    @include rmin(768) {
      min-height: 360px; } }
  &__content {
    padding: 30px 20px 50px;
    &_title {
      padding-bottom: 20px; }
    &_no-pt {
      padding-top: 0 !important; }
    @include rmin(1024) {
      padding: 30px 30px 50px 2vw;
      &_title {
        padding-bottom: 20px; } }
    @include rmin(1366) {
      padding: 30px 50px 50px 2vw;
      &_title {
        padding-bottom: 20px; } }
    @include rmin(1600) {
      padding-left: 8vw; }
    @include rmin(1920) {
      padding-left: 13vw; } }
  &__text {
    &_white {
      color: $white; }
    &_l {
      font-size: 14px;
      @include rmin(768) {
        font-size: 18px; }
      @include rmin(1024) {
        font-size: 24px; } }
    & + & {
      margin-top: 15px; } }
  &__pic {
    height: 100%;
    min-height: 180px;
    position: relative;
    @include cover;
    @include rmin(768) {
      min-height: 395px; }
    @include rmin(1366) {
      min-height: 360px; } }
  &__pic-title {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    line-height: 1em;
    margin-bottom: 25px;
    @include rmin(768) {
      font-size: 24px; }
    @include os-extra;
    &_right {
      @include rmin(1024) {
        text-align: right; } }
    &_mt {
      margin-top: 10px;
      line-height: 1.2;
      @include rmin(1024) {
        margin-top: 50px; } } }
  &__calc-content {
    padding: 10px 0 0 20px;
    background-color: rgba(0,0,0,.6);
    @include rmin(768) {
      padding: 20px 0 0 20px; }
    @include rmin(1024) {
      padding: 55px 0 0 55px; } }
  &__pic-content {
    width: 100%;
    height: 100%;
    min-height: 180px;
    padding: 20px;
    background-color: rgba(0,0,0,.3);
    position: relative;
    @include rmin(768) {
      min-height: 395px; }
    @include rmin(1024) {
      padding: 25px;
      &_l {
        padding: 55px; } }
    @include rmin(1600) {
      padding-right: 8vw; }
    @include rmin(1920) {
      padding-right: 13vw; }
    &_blue {
      background-color: rgba($accent,.8); }
    &_dark {
      background-color: rgba(0,0,0,.6); } }
  &__list {
    counter-reset: list;
    margin-top: 25px;
    @include rmin(768) {
      margin-top: 70px; } }
  &__list-item {
    position: relative;
    padding-left: 35px;
    @include rmin(768) {
      padding-left: 60px; }
    & + & {
      margin-top: 20px;
      @include rmin(768) {
        margin-top: 40px; } }
    &:before {
      counter-increment: list;
      content: counter(list);
      position: absolute;
      left: 0;
      top: 25%;
      transform: translateY(-50%);
      font-size: 48px;
      color: $accent;
      @include rs-bold;
      @include rmin(768) {
        font-size: 72px;
        top: 50%; } } }
  &__btn {
    margin-top: 30px;
    text-align: center;
    @include rmin(768) {
      text-align: left;
      margin-top: 50px; }
    &_select {
      position: relative;
      @include rmin(768) {
        max-width: 75%; } }
    &_s {
      margin-top: 30px; } }
  &__select {
    margin-top: 25px; } }

// slider styles

.grid-item-slider-container {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: .5s;
  z-index: -1;
  &.active {
    opacity: 1;
    z-index: 1; }
  .grid-item {
    &__slider {
      width: 100%;
      height: 100%; }
    &__slide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

    &__slider-nav {
      position: absolute;
      margin-left: -10px;
      bottom: 0;
      display: none;
      @include rmin(768) {
        display: block; }
      @include rmin(1024) {
        margin-left: 0;
        bottom: 15px; }
      .grid-item {
        &__slide-nav {
          height: 60px;
          width: 90px;
          margin-left: 10px;
          @include rmin(1024) {
            width: 60px;
            height: 40px; }
          @include rmin(1366) {
            height: 60px;
            width: 90px; } } } } }

  .slick-arrow {
    background-color: transparent;
    font-size: 0;
    width: 30px;
    height: 25px;
    position: absolute;
    top: 10px;
    z-index: 99;
    @include contain;
    transition: background .3s ease;
    &.slick-prev {
      background-image: url(../img/arrow-left.svg);
      left: 10px; }
    &.slick-next {
      background-image: url(../img/arrow-right.svg);
      right: 10px; }
    &.slick-disabled {
      opacity: .5; } }
  .slick-list {
    height: 100%; }
  .slick-track {
    height: 100%; }
  .slick-slide {
    height: 100%; } }
