.review-slider {
  padding-top: 40px;
  @include rmin(1024) {
    padding-top: 0; }
  .slick-arrow {
    background-color: transparent;
    font-size: 0;
    width: 30px;
    height: 25px;
    position: absolute;
    top: 0;
    @include contain;
    transition: background .3s ease;
    @include rmin(1024) {
      top: 30px; }
    &.slick-prev {
      background-image: url(../img/arrow-left.svg);
      left: 0;
      @include rmin(768) {
        left: auto;
        right: 60px; }
      &:hover:not(.slick-disabled) {
        background-image: url(../img/arrow-left-blue.svg); } }
    &.slick-next {
      background-image: url(../img/arrow-right.svg);
      right: 0;
      &:hover:not(.slick-disabled) {
        background-image: url(../img/arrow-right-blue.svg); } }
    &.slick-disabled {
      opacity: .5; } } }

.review-slide {
  @include rmin(1024) {
    display: flex !important;
    justify-content: space-between; }
  &__col {
    @include rmin(1024) {
      width: 48%; } } }

.review {
  margin-bottom: 20px;
  @include rmin(1024) {
    margin-bottom: 0; }
  & + & {
    @include rmin(1024) {
      margin-top: 60px; } }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
  &__img {
    margin-right: 15px;
    width: 60px;
    @include rmin(1024) {
      width: 120px; } }
  &__title {
    @include os-extra;
    font-size: 18px;
    @include rmin(1024) {
      font-size: 24px;
      margin-bottom: 20px; }
    span {
      display: block; } }
  &__video {
    padding: 10px 20px;
    @include cover;
    position: relative;
    height: 180px;
    @include rmin(768) {
      height: 360px;
      padding: 30px; } }
  &__video-title {
    @include os-extra;
    font-size: 18px;
    color: #fff;
    @include rmin(1024) {
      font-size: 24px; } } }
