.btn {
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  color: $white;
  border: 3px solid $white;
  padding: 10px 25px;
  box-sizing: border-box;
  @include os-semi;
  font-size: 14px;
  width: 100%;
  text-align: center;
  @include rmin(768) {
    font-size: 18px;
    width: auto; }
  @include rmin(1024) {
    transition: .3s ease;
    &:hover,
    &:active {
      background-color: $white;
      color: $accent; } }
  &_text-s {
    font-size: 14px;
    @include rmin(768) {
      font-size: 14px; }
    @include rmin(1920) {
      font-size: 18px; } }
  &_border-blue {
    border-color: $accent;
    color: $accent;
    @include rmin(1024) {
      transition: .3s ease;
      &:hover,
      &:active {
        background-color: $accent;
        color: $white; } } }
  &_blue {
    background-color: $accent;
    border-color: $accent; }
  &_white {
    background-color: $white;
    color: $accent;
    @include rmin(1024) {
      transition: .3s ease;
      &:hover,
      &:active {
        background-color: $accent;
        color: $white;
        border-color: $white; } } }
  &_header {
    border: none;
    height: 70px;
    line-height: 50px;
    margin-top: 10px;
    display: none;
    @include os-bold;
    @include rmin(1024) {
      display: inline-block;
      font-size: 14px;
      padding: 10px 15px; }
    @include rmin(1366) {
      font-size: 18px;
      padding: 10px 25px; } }
  &_center {
    margin: 0 auto; }
  &_l {
    padding: 10px 75px; }
  &_sm {
    border: none;
    padding: 13px 15px; }
  &_phone-order {
    @include rmin(1024) {
      &:hover,
      &:active {
        background-color: $accent;
        color: $white; } } }
  &_block {
    display: block; }
  &_tel {
    padding: 4px 0 0 0;
    background-color: transparent;
    position: relative;
    border: none;
    opacity: 1;
    .icon-phone {
      fill: $white;
      font-size: 22px; }
    @include rmin(768) {
      line-height: 50px;
      padding: 10px 0;
      padding-top: 15px;
      height: 70px;
      .icon-phone {
        font-size: 25px; } }
    @include rmin(1024) {
      padding: 15px 15px 10px;
      margin-top: 10px;
      opacity: .5;
      background-color: $white;
      opacity: .5;
      transition: opacity .3s ease;
      &:hover {
        opacity: 1; }
      .icon-phone {
        fill: $accent; } }
    @include rmin(1366) {
      padding: 10px 25px;
      padding-top: 15px; } } }

.video-btn {
  @include os-semi;
  font-size: 14px;
  color: $white;
  background-color: transparent;
  @include vcenter;
  @include rmin(1024) {
    &:hover,
    &:active {
      .icon-play {
        fill: $accent; } } }

  .icon-play {
    display: block;
    margin: 0 auto 5px;
    text-align: center;
    font-size: 28px;
    fill: $white;
    transition: .3s ease;
    @include rmin(768) {
      font-size: 60px; } } }

.video-link {
  @include vcenter;
  width: 115px;
  height: 70px;
  z-index: 99;
  @include rmin(1024) {
    display: none; } }

.btn-download {
  span {
    display: none;
    @include rmin(1024) {
      display: inline; } }
  .icon-download {
    font-size: 20px;
    fill: $accent;
    margin-right: 10px;
    margin-bottom: -5px;
    @include rmin(1024) {
      display: none; } } }
