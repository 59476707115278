.animate {
  transition: transform 2s;
  &:before {
    content: '';
    position: absolute;
    background-color: #fff;
    display: block;
    width: 102%;
    height: 100%;
    z-index: 99;
    transition: width 1.4s cubic-bezier(.165,.84,.44,1); }
  &_to-l {
    transform: translateX(1%);
    &:before {
      left: -1%; } }
  &_to-r {
    transform: translateX(-1%);
    &:before {
      right: -1%; } }
  &.active {
    transform: translateX(0%);
    &:before {
      width: 0%; } } }
