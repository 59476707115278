.range-slider {
  width: 100%;
  &.ui-slider-horizontal {
    border: none;
    border-bottom: 3px solid #b7b7b7;
    position: relative;
    &::after,
    &::before {
      content: '';
      display: block;
      width: 3px;
      background-color: $accent;
      position: absolute;
      height: 20px;
      top: 1px;
      @include rmin(768) {
        top: 3px; } }
    &::before {
      left: 0; }
    &::after {
      right: 0; }
    .ui-slider-handle {
      top: 22px;
      border: none !important;
      background-color: transparent !important;
      color: $black !important;
      text-align: center;
      margin-left: -38px;
      width: 80px;
      &::before {
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        background-color: $accent;
        border-radius: 50%;
        position: absolute;
        top: -23px;
        left: 50%;
        transform: translateX(-50%);
        @include rmin(768) {
          top: -20px; } } }
    .ui-slider-range {
      height: 3px;
      top: 9px;
      background-color: $accent;
      @include rmin(768) {
        top: 11px; } } } }
