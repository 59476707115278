.footer {
  line-height: 70px;
  background-color: $black;
  color: $white;
  @include rmin(768) {
    height: 90px;
    line-height: 90px; }
  &__inner {
    @include rmin(768) {
      display: flex;
      justify-content: space-between;
      align-items: center; } } }
