.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  @include rmin(768) {
    width: 33.333%; }
  &__item {
    display: inline-block;
    height: 35px;
    width: 35px;
    position: relative;
    & + & {
      margin-left: 30px; }
    .icon-yt,
    .icon-gl,
    .icon-vk,
    .icon-fb {
      font-size: 35px;
      fill: $white;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s ease; }
    @include rmin(1024) {
      &:hover,
      &:active {
        .icon-yt,
        .icon-gl,
        .icon-vk,
        .icon-fb {
          fill: $accent; } } } } }
