// variables

$accent: #005ca9;
$white: #fff;
$black: #202020;

// fonts

@include font(OpenSans-Regular, OpenSans-Regular);
@include font(OpenSans-ExtraBold, OpenSans-ExtraBold);
@include font(OpenSans-Semibold, OpenSans-Semibold);
@include font(RobotoSlab-Bold, RobotoSlab-Bold);
@include font(OpenSans-Bold, OpenSans-Bold);

$default-fonts: Arial, sans-serif;

@mixin os-reg {
   font-family: OpenSans-Regular, $default-fonts; }
@mixin os-extra {
   font-family: OpenSans-ExtraBold, $default-fonts; }
@mixin os-semi {
   font-family: OpenSans-Semibold, $default-fonts; }
@mixin os-bold {
   font-family: OpenSans-Bold, $default-fonts; }
@mixin rs-bold {
   font-family: RobotoSlab-Bold, $default-fonts; }

// base styles

*,
:before,
:after {
  box-sizing: border-box; }

body {
  background: #FFF;
  line-height: 1.3;
  @include os-reg;
  color: $black;
  font-size: 14px;
  @include rmin(768) {
    font-size: 18px; }
  &.popup-open {
    @include rmin(1024) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow: hidden; } } }

img {
  max-width: 100%; }

a {
  text-decoration: none;
  color: $white;
  cursor: pointer; }

textarea {
  resize: vertical; }


.copyright {
  font-size: 14px;
  text-align: center;
  @include rmin(768) {
    width: 33.333%;
    text-align: right; } }
