.phone-order {
  &__text {
    @include os-semi;
    padding-right: 25px;
    @include r(1366) {
      display: block;
      margin-bottom: 10px; } }
  &__input {
    display: inline-block;
    border: 3px solid $accent;
    border-radius: 0;
    padding: 10px 15px;
    font-size: 18px;
    @include os-semi;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    @include rmin(768) {
      width: auto;
      margin-bottom: 0; } } }
