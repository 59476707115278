.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: .3s ease;
  @include os-bold;
  @include rmin(768) {
    height: 90px; }
  @include rmin(1024) {
    font-size: 14px; }
  @include rmin(1366) {
    font-size: 18px; }
  &__logo {
    background-image: url(../img/logo-white.png);
    @include contain;
    width: 105px;
    height: 40px;
    transition: .3s ease;
    @include rmin(768) {
      width: 170px;
      height: 65px; }
    @include rmin(1024) {
      width: 150px;
      &:hover {
        background-image: url(../img/logo.png); } }
    @include rmin(1366) {
      width: 170px; } }
  &__contacts {
    @include rmin(1600) {
      margin-left: -50px; } }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    @include rmin(1024) {
      padding: 0; } } }

&.fixed {
  position: fixed;
  background-color: $white;
  .header {
    &__logo {
      background-image: url(../img/logo.png); } }
  .btn_tel {
    opacity: 1;
    @include rmin(1024) {
      transition: .3s ease;
      &:hover,
      &:active {
        background-color: $accent;
        .icon-phone {
          fill: $white; } } }
    .icon-phone {
      fill: $accent;
      @include rmin(768) {
        fill: $black; }
      @include rmin(1024) {
        fill: $accent; } } }
  .btn_header {
    @include rmin(1024) {
      transition: .3s ease;
      &:hover,
      &:active {
        background-color: $white;
        color: $accent; } } }
  .menu {
    a {
      color: $black; }
    .btn {
      color: $white; } }
  .menu-btn {
    &__dash {
      background-color: $black; } } }


&.header-call-mob {
  display: block;
  position: static;
  background-color: $white;
  .header {
    &__logo {
      background-image: url(../img/logo.png); } }
  @include rmin(768) {
    display: none; }
  .menu-btn {
    visibility: hidden; }
  .popup__close {
    padding: 15px;
    display: block; } }

&.header-application-mob {
  display: block;
  position: static;
  background-color: $white;
  @include rmin(768) {
    display: none; }
  .header {
    &__logo {
      background-image: url(../img/logo.png); } }
  .btn_tel {
    opacity: 1;
    .icon-phone {
      fill: $accent; } }
  .menu-btn {
    visibility: hidden; } }
