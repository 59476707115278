.benefits {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
  @include rmin(768) {
    margin-top: 80px; } }

.benefit {
  color: #fff;
  width: 50%;
  &:first-child {
    width: 150px;
    margin: 0 25% 30px;
    @include rmin(768) {
      width: 33%;
      margin: 0; }
    @include rmin(1024) {
      width: 150px;
      margin: 0 25% 30px; }
    @include rmin(1366) {
      width: 32%;
      margin: 0; } }
  @include rmin(768) {
    width: 33%;
    padding-right: 50px;
    margin: 0 auto; }
  @include rmin(1024) {
    width: 200px; }
  &__title {
    @include os-extra;
    font-size: 18px;
    @include rmin(768) {
      font-size: 24px; } }
  &__text {
    @include os-semi;
    line-height: 1em;
    position: relative;
    span {
      @include os-extra;
      font-size: 36px;
      line-height: .8em;
      @include rmin(768) {
        font-size: 60px; } } }
  &__val {
    @include os-extra;
    font-size: 36px;
    position: absolute;
    left: 85px;
    top: 0;
    @include rmin(768) {
      font-size: 60px;
      left: 110px; }
    &_l {
      font-size: 60px;
      @include rmin(768) {
        font-size: 100px; } } } }
