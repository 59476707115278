.result {
  background-color: $accent;
  padding: 20px;
  width: 100%;
  @include rmin(1024) {
    position: absolute;
    height: 50%;
    bottom: 0;
    left: -100%;
    padding: 40px 50px;
    padding-left: 2vw; }
  @include rmin(1600) {
    padding-left: 8vw; }
  @include rmin(1920) {
    padding-left: 13vw; }
  &__text {
    @include os-semi;
    font-size: 18px;
    color: #fff;
    @include rmin(768) {
      font-size: 24px; } }
  &__title {
    @include os-extra;
    font-size: 18px;
    color: #fff;
    margin-top: 15px;
    @include rmin(768) {
      font-size: 30px;
      margin-top: 20px; } } }
