html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-dashboard {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-download {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-email {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-fb {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-gl {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-location {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-open {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-vk {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-yt {
  width: 1em;
  height: 1em;
  fill: initial;
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("fonts/OpenSans-Regular.woff2") format("woff2"), url("fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSans-ExtraBold;
  src: url("fonts/OpenSans-ExtraBold.woff2") format("woff2"), url("fonts/OpenSans-ExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSans-Semibold;
  src: url("fonts/OpenSans-Semibold.woff2") format("woff2"), url("fonts/OpenSans-Semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoSlab-Bold;
  src: url("fonts/RobotoSlab-Bold.woff2") format("woff2"), url("fonts/RobotoSlab-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSans-Bold;
  src: url("fonts/OpenSans-Bold.woff2") format("woff2"), url("fonts/OpenSans-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*,
:before,
:after {
  box-sizing: border-box;
}

body {
  background: #FFF;
  line-height: 1.3;
  font-family: OpenSans-Regular, Arial, sans-serif;
  color: #202020;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  body.popup-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

textarea {
  resize: vertical;
}

.copyright {
  font-size: 14px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .copyright {
    width: 33.333%;
    text-align: right;
  }
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: opacity .3s ease, visibility .3s ease;
}

.popup.is-open {
  opacity: 1;
  visibility: visible;
}

.popup__in {
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .popup__in {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 1024px) {
  .popup__in {
    width: auto;
  }
}

.popup__header {
  height: 54px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .popup__header {
    display: none;
  }
}

.popup__header-logo {
  background-image: url(../img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  width: 105px;
  height: 40px;
}

.popup__body {
  position: relative;
  max-width: 100%;
  background: #fff;
  padding: 50px 75px;
}

.popup__body .popup__close {
  top: 0;
  right: 0;
}

.popup__body .popup__close .icon-close {
  font-size: 30px;
  fill: #000;
}

.popup__close {
  position: absolute;
  padding: 20px;
  right: 0;
  top: 0;
  background-color: transparent;
  z-index: 99;
}

.popup__close .icon-close {
  fill: #202020;
  font-size: 18px;
}

@media only screen and (min-width: 1024px) {
  .popup__close .icon-close {
    transition: .3s ease;
  }
  .popup__close .icon-close:hover, .popup__close .icon-close:active {
    fill: #df1d23;
  }
}

.popup_call .phone-order__input {
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .popup_call .phone-order__input {
    font-size: 18px;
    width: auto;
    margin-bottom: 0;
  }
}

.popup_call .phone-order__text {
  display: block;
  margin-bottom: 10px;
}

.popup_call .phone-order .btn_phone-order {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .popup_call .phone-order .btn_phone-order {
    width: auto;
  }
}

.popup_call .popup__body {
  padding: 10px 30px 30px;
}

@media only screen and (min-width: 768px) {
  .popup_call .popup__body {
    padding: 50px 75px;
  }
}

@media only screen and (min-width: 1024px) {
  .popup_call .popup__body {
    padding: 35px;
  }
}

@media only screen and (min-width: 1366px) {
  .popup_call .popup__body {
    padding: 50px 75px;
  }
}

.popup_call .popup__close {
  display: block;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 768px) {
  .popup_call .popup__close {
    padding: 20px;
  }
  .popup_call .popup__close .icon-close {
    font-size: 30px;
    fill: #000;
  }
}

@media only screen and (min-width: 1024px) {
  .popup_call .popup__close .icon-close {
    font-size: 18px;
  }
}

.popup_call .popup__close .icon-close {
  fill: #005ca9;
}

.popup_application .popup__body {
  padding: 0 30px 50px;
}

@media only screen and (min-width: 768px) {
  .popup_application .popup__body {
    padding: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .popup_application .popup__body {
    padding: 50px 100px;
  }
}

.popup_application .popup__close {
  padding: 10px;
  top: 10px;
  right: 10px;
}

@media only screen and (min-width: 768px) {
  .popup_application .popup__close {
    padding: 20px;
    top: 0;
    right: 0;
  }
}

.popup_application .application-bg {
  top: 0;
}

.popup_application .application {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .popup_application .application {
    width: 740px;
  }
}

.popup_application .application .title {
  font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .popup_application .application .title {
    font-size: 36px;
    text-align: left;
  }
}

.popup_application .application .title_m-margin {
  margin-bottom: 0;
}

.popup_application .form__label {
  font-size: 14px;
}

.popup_application .form__input {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .popup_application .form__input {
    font-size: 18px;
  }
}

.popup_application .form__input::placeholder {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .popup_application .form__input::placeholder {
    font-size: 18px;
  }
}

.popup_application .form__item {
  padding: 0;
  padding-top: 25px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .popup_application .form__item {
    padding: 25px 10px 0;
    width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .popup_application .form__item_wide {
    width: 100%;
  }
}

.popup_application .ui-selectmenu-button {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .popup_application .ui-selectmenu-button {
    font-size: 18px;
  }
}

.popup_video {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .popup_video {
    display: block;
  }
}

.popup_video .popup__body {
  width: 85vw;
  height: calc(85vw / 1.5);
  padding: 50px;
}

@media only screen and (min-width: 1366px) {
  .popup_video .popup__body {
    width: 64vw;
    height: 79vh;
    padding: 85px 90px;
  }
}

.popup_video iframe {
  width: 100%;
  height: 100%;
}

.btn {
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  color: #fff;
  border: 3px solid #fff;
  padding: 10px 25px;
  box-sizing: border-box;
  font-family: OpenSans-Semibold, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .btn {
    font-size: 18px;
    width: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .btn {
    transition: .3s ease;
  }
  .btn:hover, .btn:active {
    background-color: #fff;
    color: #005ca9;
  }
}

.btn_text-s {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .btn_text-s {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1920px) {
  .btn_text-s {
    font-size: 18px;
  }
}

.btn_border-blue {
  border-color: #005ca9;
  color: #005ca9;
}

@media only screen and (min-width: 1024px) {
  .btn_border-blue {
    transition: .3s ease;
  }
  .btn_border-blue:hover, .btn_border-blue:active {
    background-color: #005ca9;
    color: #fff;
  }
}

.btn_blue {
  background-color: #005ca9;
  border-color: #005ca9;
}

.btn_white {
  background-color: #fff;
  color: #005ca9;
}

@media only screen and (min-width: 1024px) {
  .btn_white {
    transition: .3s ease;
  }
  .btn_white:hover, .btn_white:active {
    background-color: #005ca9;
    color: #fff;
    border-color: #fff;
  }
}

.btn_header {
  border: none;
  height: 70px;
  line-height: 50px;
  margin-top: 10px;
  display: none;
  font-family: OpenSans-Bold, Arial, sans-serif;
}

@media only screen and (min-width: 1024px) {
  .btn_header {
    display: inline-block;
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 1366px) {
  .btn_header {
    font-size: 18px;
    padding: 10px 25px;
  }
}

.btn_center {
  margin: 0 auto;
}

.btn_l {
  padding: 10px 75px;
}

.btn_sm {
  border: none;
  padding: 13px 15px;
}

@media only screen and (min-width: 1024px) {
  .btn_phone-order:hover, .btn_phone-order:active {
    background-color: #005ca9;
    color: #fff;
  }
}

.btn_block {
  display: block;
}

.btn_tel {
  padding: 4px 0 0 0;
  background-color: transparent;
  position: relative;
  border: none;
  opacity: 1;
}

.btn_tel .icon-phone {
  fill: #fff;
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .btn_tel {
    line-height: 50px;
    padding: 10px 0;
    padding-top: 15px;
    height: 70px;
  }
  .btn_tel .icon-phone {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .btn_tel {
    padding: 15px 15px 10px;
    margin-top: 10px;
    opacity: .5;
    background-color: #fff;
    opacity: .5;
    transition: opacity .3s ease;
  }
  .btn_tel:hover {
    opacity: 1;
  }
  .btn_tel .icon-phone {
    fill: #005ca9;
  }
}

@media only screen and (min-width: 1366px) {
  .btn_tel {
    padding: 10px 25px;
    padding-top: 15px;
  }
}

.video-btn {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1024px) {
  .video-btn:hover .icon-play, .video-btn:active .icon-play {
    fill: #005ca9;
  }
}

.video-btn .icon-play {
  display: block;
  margin: 0 auto 5px;
  text-align: center;
  font-size: 28px;
  fill: #fff;
  transition: .3s ease;
}

@media only screen and (min-width: 768px) {
  .video-btn .icon-play {
    font-size: 60px;
  }
}

.video-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 115px;
  height: 70px;
  z-index: 99;
}

@media only screen and (min-width: 1024px) {
  .video-link {
    display: none;
  }
}

.btn-download span {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .btn-download span {
    display: inline;
  }
}

.btn-download .icon-download {
  font-size: 20px;
  fill: #005ca9;
  margin-right: 10px;
  margin-bottom: -5px;
}

@media only screen and (min-width: 1024px) {
  .btn-download .icon-download {
    display: none;
  }
}

.text_comment {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 45px;
}

.text_l {
  line-height: 1.5;
  margin-bottom: 30vh;
}

@media only screen and (min-width: 768px) {
  .text_l {
    margin-bottom: 20em;
  }
}

@media only screen and (min-width: 1024px) {
  .text_l {
    margin-bottom: 2.7em;
  }
}

@media only screen and (min-width: 1680px) {
  .text_l {
    font-size: 24px;
  }
}

.text_white {
  color: #fff;
}

.title {
  font-family: RobotoSlab-Bold, Arial, sans-serif;
  color: #202020;
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1em;
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 48px;
    margin-bottom: 50px;
  }
  .title_sm-margin {
    margin-bottom: 15px;
  }
  .title_m-margin {
    margin-bottom: 30px;
  }
}

.title span {
  font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .title span {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .title span {
    font-size: 100px;
  }
}

.title_main {
  font-size: 30px;
  color: #fff;
  margin-bottom: .7em;
  margin-top: 20vh;
}

@media only screen and (min-width: 1024px) {
  .title_main {
    font-size: 62px;
    margin-top: 2.9em;
  }
}

@media only screen and (min-width: 1680px) {
  .title_main {
    margin-top: 3.4em;
  }
}

@media only screen and (min-width: 1680px) {
  .title_main {
    font-size: 72px;
  }
}

.title_grid {
  color: #005ca9;
  font-size: 18px;
}

@media only screen and (min-width: 768px) {
  .title_grid {
    margin-top: 30px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1366px) {
  .title_grid {
    font-size: 60px;
  }
}

.title_l {
  font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .title_l {
    font-size: 72px;
  }
}

.title_white {
  color: #fff;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: .3s ease;
  font-family: OpenSans-Bold, Arial, sans-serif;
}

@media only screen and (min-width: 768px) {
  .header {
    height: 90px;
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1366px) {
  .header {
    font-size: 18px;
  }
}

.header__logo {
  background-image: url(../img/logo-white.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  width: 105px;
  height: 40px;
  transition: .3s ease;
}

@media only screen and (min-width: 768px) {
  .header__logo {
    width: 170px;
    height: 65px;
  }
}

@media only screen and (min-width: 1024px) {
  .header__logo {
    width: 150px;
  }
  .header__logo:hover {
    background-image: url(../img/logo.png);
  }
}

@media only screen and (min-width: 1366px) {
  .header__logo {
    width: 170px;
  }
}

@media only screen and (min-width: 1600px) {
  .header__contacts {
    margin-left: -50px;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
}

@media only screen and (min-width: 1024px) {
  .header__inner {
    padding: 0;
  }
}

.fixed {
  position: fixed;
  background-color: #fff;
}

.fixed .header__logo {
  background-image: url(../img/logo.png);
}

.fixed .btn_tel {
  opacity: 1;
}

@media only screen and (min-width: 1024px) {
  .fixed .btn_tel {
    transition: .3s ease;
  }
  .fixed .btn_tel:hover, .fixed .btn_tel:active {
    background-color: #005ca9;
  }
  .fixed .btn_tel:hover .icon-phone, .fixed .btn_tel:active .icon-phone {
    fill: #fff;
  }
}

.fixed .btn_tel .icon-phone {
  fill: #005ca9;
}

@media only screen and (min-width: 768px) {
  .fixed .btn_tel .icon-phone {
    fill: #202020;
  }
}

@media only screen and (min-width: 1024px) {
  .fixed .btn_tel .icon-phone {
    fill: #005ca9;
  }
}

@media only screen and (min-width: 1024px) {
  .fixed .btn_header {
    transition: .3s ease;
  }
  .fixed .btn_header:hover, .fixed .btn_header:active {
    background-color: #fff;
    color: #005ca9;
  }
}

.fixed .menu a {
  color: #202020;
}

.fixed .menu .btn {
  color: #fff;
}

.fixed .menu-btn__dash {
  background-color: #202020;
}

.header-call-mob {
  display: block;
  position: static;
  background-color: #fff;
}

.header-call-mob .header__logo {
  background-image: url(../img/logo.png);
}

@media only screen and (min-width: 768px) {
  .header-call-mob {
    display: none;
  }
}

.header-call-mob .menu-btn {
  visibility: hidden;
}

.header-call-mob .popup__close {
  padding: 15px;
  display: block;
}

.header-application-mob {
  display: block;
  position: static;
  background-color: #fff;
}

@media only screen and (min-width: 768px) {
  .header-application-mob {
    display: none;
  }
}

.header-application-mob .header__logo {
  background-image: url(../img/logo.png);
}

.header-application-mob .btn_tel {
  opacity: 1;
}

.header-application-mob .btn_tel .icon-phone {
  fill: #005ca9;
}

.header-application-mob .menu-btn {
  visibility: hidden;
}

.footer {
  line-height: 70px;
  background-color: #202020;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .footer {
    height: 90px;
    line-height: 90px;
  }
}

@media only screen and (min-width: 768px) {
  .footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: url(../img/main-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media only screen and (min-width: 1024px) {
  .main {
    background-image: none;
  }
}

.main__info {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .main__info {
    width: 50%;
    padding-right: 0;
    padding: 2vw;
  }
}

@media only screen and (min-width: 1680px) {
  .main__info {
    padding-left: 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .main__info {
    padding-left: 13vw;
  }
}

@media only screen and (max-width: 1023px) {
  .main__video {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .main__video {
    width: 50%;
    height: 100%;
    position: relative;
  }
}

.video-block {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: none;
}

@media only screen and (min-width: 1024px) {
  .video-block {
    display: block;
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .socials {
    width: 33.333%;
  }
}

.socials__item {
  display: inline-block;
  height: 35px;
  width: 35px;
  position: relative;
}

.socials__item + .socials__item {
  margin-left: 30px;
}

.socials__item .icon-yt,
.socials__item .icon-gl,
.socials__item .icon-vk,
.socials__item .icon-fb {
  font-size: 35px;
  fill: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: .3s ease;
}

@media only screen and (min-width: 1024px) {
  .socials__item:hover .icon-yt,
  .socials__item:hover .icon-gl,
  .socials__item:hover .icon-vk,
  .socials__item:hover .icon-fb, .socials__item:active .icon-yt,
  .socials__item:active .icon-gl,
  .socials__item:active .icon-vk,
  .socials__item:active .icon-fb {
    fill: #005ca9;
  }
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .grid_reverse {
    flex-direction: row-reverse;
  }
  .grid_reverse .grid-item__content {
    padding: 30px 2vw 50px 30px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .grid_reverse .grid-item__content {
    padding: 30px 2vw 50px 50px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1600px) {
  .grid_reverse .grid-item__content {
    padding-right: 8vw;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1920px) {
  .grid_reverse .grid-item__content {
    padding-right: 13vw;
  }
}

@media only screen and (min-width: 1024px) {
  .grid_reverse .grid-item__pic-content {
    padding: 25px 25px 25px 2vw;
  }
  .grid_reverse .grid-item__pic-content_l {
    padding: 55px 55px 55px 2vw;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1600px) {
  .grid_reverse .grid-item__pic-content {
    padding-left: 8vw;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1920px) {
  .grid_reverse .grid-item__pic-content {
    padding-left: 13vw;
  }
}

.grid_mt {
  padding-top: 20px;
}

@media only screen and (min-width: 1024px) {
  .grid_mt {
    padding-top: 90px;
  }
}

.grid_bg {
  background-color: #fff;
  background-image: url(../img/application-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.grid-item {
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .grid-item {
    width: 50%;
  }
}

.grid-item_blue {
  background-color: #005ca9;
}

.grid-item_gallery {
  min-height: 180px;
}

@media only screen and (min-width: 768px) {
  .grid-item_gallery {
    min-height: 360px;
  }
}

.grid-item__content {
  padding: 30px 20px 50px;
}

.grid-item__content_title {
  padding-bottom: 20px;
}

.grid-item__content_no-pt {
  padding-top: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .grid-item__content {
    padding: 30px 30px 50px 2vw;
  }
  .grid-item__content_title {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .grid-item__content {
    padding: 30px 50px 50px 2vw;
  }
  .grid-item__content_title {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1600px) {
  .grid-item__content {
    padding-left: 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .grid-item__content {
    padding-left: 13vw;
  }
}

.grid-item__text_white {
  color: #fff;
}

.grid-item__text_l {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .grid-item__text_l {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-item__text_l {
    font-size: 24px;
  }
}

.grid-item__text + .grid-item__text {
  margin-top: 15px;
}

.grid-item__pic {
  height: 100%;
  min-height: 180px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media only screen and (min-width: 768px) {
  .grid-item__pic {
    min-height: 395px;
  }
}

@media only screen and (min-width: 1366px) {
  .grid-item__pic {
    min-height: 360px;
  }
}

.grid-item__pic-title {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  line-height: 1em;
  margin-bottom: 25px;
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
}

@media only screen and (min-width: 768px) {
  .grid-item__pic-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-item__pic-title_right {
    text-align: right;
  }
}

.grid-item__pic-title_mt {
  margin-top: 10px;
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  .grid-item__pic-title_mt {
    margin-top: 50px;
  }
}

.grid-item__calc-content {
  padding: 10px 0 0 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

@media only screen and (min-width: 768px) {
  .grid-item__calc-content {
    padding: 20px 0 0 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-item__calc-content {
    padding: 55px 0 0 55px;
  }
}

.grid-item__pic-content {
  width: 100%;
  height: 100%;
  min-height: 180px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
}

@media only screen and (min-width: 768px) {
  .grid-item__pic-content {
    min-height: 395px;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-item__pic-content {
    padding: 25px;
  }
  .grid-item__pic-content_l {
    padding: 55px;
  }
}

@media only screen and (min-width: 1600px) {
  .grid-item__pic-content {
    padding-right: 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .grid-item__pic-content {
    padding-right: 13vw;
  }
}

.grid-item__pic-content_blue {
  background-color: rgba(0, 92, 169, 0.8);
}

.grid-item__pic-content_dark {
  background-color: rgba(0, 0, 0, 0.6);
}

.grid-item__list {
  counter-reset: list;
  margin-top: 25px;
}

@media only screen and (min-width: 768px) {
  .grid-item__list {
    margin-top: 70px;
  }
}

.grid-item__list-item {
  position: relative;
  padding-left: 35px;
}

@media only screen and (min-width: 768px) {
  .grid-item__list-item {
    padding-left: 60px;
  }
}

.grid-item__list-item + .grid-item__list-item {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .grid-item__list-item + .grid-item__list-item {
    margin-top: 40px;
  }
}

.grid-item__list-item:before {
  counter-increment: list;
  content: counter(list);
  position: absolute;
  left: 0;
  top: 25%;
  transform: translateY(-50%);
  font-size: 48px;
  color: #005ca9;
  font-family: RobotoSlab-Bold, Arial, sans-serif;
}

@media only screen and (min-width: 768px) {
  .grid-item__list-item:before {
    font-size: 72px;
    top: 50%;
  }
}

.grid-item__btn {
  margin-top: 30px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .grid-item__btn {
    text-align: left;
    margin-top: 50px;
  }
}

.grid-item__btn_select {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .grid-item__btn_select {
    max-width: 75%;
  }
}

.grid-item__btn_s {
  margin-top: 30px;
}

.grid-item__select {
  margin-top: 25px;
}

.grid-item-slider-container {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: .5s;
  z-index: -1;
}

.grid-item-slider-container.active {
  opacity: 1;
  z-index: 1;
}

.grid-item-slider-container .grid-item__slider {
  width: 100%;
  height: 100%;
}

.grid-item-slider-container .grid-item__slide {
  width: 100%;
  height: 100%;
}

.grid-item-slider-container .grid-item__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item-slider-container .grid-item__slider-nav {
  position: absolute;
  margin-left: -10px;
  bottom: 0;
  display: none;
}

@media only screen and (min-width: 768px) {
  .grid-item-slider-container .grid-item__slider-nav {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-item-slider-container .grid-item__slider-nav {
    margin-left: 0;
    bottom: 15px;
  }
}

.grid-item-slider-container .grid-item__slider-nav .grid-item__slide-nav {
  height: 60px;
  width: 90px;
  margin-left: 10px;
}

@media only screen and (min-width: 1024px) {
  .grid-item-slider-container .grid-item__slider-nav .grid-item__slide-nav {
    width: 60px;
    height: 40px;
  }
}

@media only screen and (min-width: 1366px) {
  .grid-item-slider-container .grid-item__slider-nav .grid-item__slide-nav {
    height: 60px;
    width: 90px;
  }
}

.grid-item-slider-container .slick-arrow {
  background-color: transparent;
  font-size: 0;
  width: 30px;
  height: 25px;
  position: absolute;
  top: 10px;
  z-index: 99;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  transition: background .3s ease;
}

.grid-item-slider-container .slick-arrow.slick-prev {
  background-image: url(../img/arrow-left.svg);
  left: 10px;
}

.grid-item-slider-container .slick-arrow.slick-next {
  background-image: url(../img/arrow-right.svg);
  right: 10px;
}

.grid-item-slider-container .slick-arrow.slick-disabled {
  opacity: .5;
}

.grid-item-slider-container .slick-list {
  height: 100%;
}

.grid-item-slider-container .slick-track {
  height: 100%;
}

.grid-item-slider-container .slick-slide {
  height: 100%;
}

.application-wrap {
  padding: 1px;
  background-color: #fff;
  background-image: url(../img/application-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.application {
  text-align: center;
  max-width: 740px;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .application {
    padding: 0 0 70px;
  }
}

.application__text {
  margin-bottom: 15px;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  *zoom: 1;
}

.form:after {
  content: " ";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1024px) {
  .form_mt {
    margin-top: 25px;
  }
}

.form__item {
  position: relative;
  padding-top: 15px;
  width: 100%;
  float: left;
  text-align: left;
  position: relative;
}

.form__item_pad-l {
  padding-top: 50px;
}

@media only screen and (min-width: 768px) {
  .form__item {
    padding: 15px 10px 0;
  }
  .form__item_pad-l {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .form__item {
    width: 50%;
    padding-top: 35px;
  }
  .form__item:nth-of-type(2n + 1) {
    padding-left: 0;
  }
  .form__item:nth-of-type(2n) {
    padding-right: 0;
  }
  .form__item_wide {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .form__item_no-pt {
    padding-top: 0;
  }
  .form__item_pad-l {
    padding-top: 60px;
  }
}

.form__label {
  display: block;
  font-family: OpenSans-Semibold, Arial, sans-serif;
  margin-bottom: 10px;
  font-size: 16px;
}

@media only screen and (min-width: 1920px) {
  .form__label {
    font-size: 18px;
  }
}

.form__input {
  border: 3px solid #202020;
  border-radius: 0;
  padding: 10px 15px;
  width: 100%;
  color: #202020;
  font-family: OpenSans-Semibold, Arial, sans-serif;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .form__input {
    font-size: 18px;
  }
}

.form__input::placeholder {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  color: #b6b6b6;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .form__input::placeholder {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .form .ui-selectmenu-menu {
    width: calc(100% - 10px);
  }
}

.benefits {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .benefits {
    margin-top: 80px;
  }
}

.benefit {
  color: #fff;
  width: 50%;
}

.benefit:first-child {
  width: 150px;
  margin: 0 25% 30px;
}

@media only screen and (min-width: 768px) {
  .benefit:first-child {
    width: 33%;
    margin: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .benefit:first-child {
    width: 150px;
    margin: 0 25% 30px;
  }
}

@media only screen and (min-width: 1366px) {
  .benefit:first-child {
    width: 32%;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .benefit {
    width: 33%;
    padding-right: 50px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .benefit {
    width: 200px;
  }
}

.benefit__title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 18px;
}

@media only screen and (min-width: 768px) {
  .benefit__title {
    font-size: 24px;
  }
}

.benefit__text {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  line-height: 1em;
  position: relative;
}

.benefit__text span {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 36px;
  line-height: .8em;
}

@media only screen and (min-width: 768px) {
  .benefit__text span {
    font-size: 60px;
  }
}

.benefit__val {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 36px;
  position: absolute;
  left: 85px;
  top: 0;
}

@media only screen and (min-width: 768px) {
  .benefit__val {
    font-size: 60px;
    left: 110px;
  }
}

.benefit__val_l {
  font-size: 60px;
}

@media only screen and (min-width: 768px) {
  .benefit__val_l {
    font-size: 100px;
  }
}

.guarantee {
  display: flex;
  margin-top: 40px;
  align-items: center;
  color: #fff;
}

.guarantee__title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 18px;
  line-height: .8em;
  position: relative;
  padding-left: 45px;
}

@media only screen and (min-width: 768px) {
  .guarantee__title {
    font-size: 30px;
    padding-left: 75px;
  }
}

.guarantee__text {
  margin-left: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 768px) {
  .guarantee__text {
    margin-left: 35px;
    margin-right: 35px;
  }
}

.guarantee__icon {
  position: absolute;
  left: 0;
  top: -10px;
  font-size: 40px;
}

@media only screen and (min-width: 768px) {
  .guarantee__icon {
    font-size: 65px;
  }
}

.guarantee__icon .icon-calendar,
.guarantee__icon .icon-dashboard {
  fill: #fff;
}

.select-container {
  width: 100%;
}

.select + .ui-selectmenu-button {
  border: 3px solid #202020;
  border-radius: 0;
  padding: 10px 15px;
  background-color: #fff;
  font-family: OpenSans-Semibold, Arial, sans-serif;
  color: #202020;
  vertical-align: middle;
}

.select + .ui-selectmenu-button.ui-button {
  width: 100%;
}

.select + .ui-selectmenu-button .ui-selectmenu-icon.ui-icon {
  margin-top: 3px;
}

.select_border-tr + .ui-selectmenu-button {
  border-color: #f0f0f0;
}

.select_border-tr + .ui-selectmenu-button.ui-selectmenu-button-open {
  border-color: #202020;
}

.select_grey + .ui-selectmenu-button {
  background-color: #f0f0f0;
}

.select + .ui-button .ui-icon {
  background-image: url(../img/select-arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}

.select + .ui-button:hover .ui-icon {
  background-image: url(../img/select-arrow.png);
}

.select + .ui-button:focus .ui-icon {
  background-image: url(../img/select-arrow.png);
}

.ui-widget {
  font-family: OpenSans-Semibold, Arial, sans-serif;
}

.ui-widget.ui-widget-content {
  border: 3px solid #202020;
  border-top: none;
  width: 100% !important;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 15px;
  font-size: 14px;
  font-family: OpenSans-Semibold, Arial, sans-serif;
}

@media only screen and (min-width: 768px) {
  .ui-menu .ui-menu-item-wrapper {
    font-size: 18px;
  }
}

.ui-selectmenu-menu {
  width: 100%;
  text-align: left;
}

.contacts-item-mb {
  margin-bottom: 30px;
  position: relative;
}

.contacts-block-wrap {
  display: flex;
  flex-wrap: wrap;
}

.contacts-block {
  padding-top: 30px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .contacts-block {
    width: 50%;
    min-height: 200px;
  }
}

.contacts-block__logo {
  display: inline-block;
  vertical-align: top;
}

@media only screen and (min-width: 768px) {
  .contacts-block__logo {
    display: block;
    min-height: 48px;
    margin-bottom: 25px;
  }
}

.contacts-block__logo .icon-location,
.contacts-block__logo .icon-phone,
.contacts-block__logo .icon-open,
.contacts-block__logo .icon-email {
  fill: #e01b22;
}

.contacts-block__logo .icon-location {
  font-size: 28px;
}

@media only screen and (min-width: 768px) {
  .contacts-block__logo .icon-location {
    font-size: 48px;
  }
}

.contacts-block__logo .icon-phone {
  font-size: 21px;
}

@media only screen and (min-width: 768px) {
  .contacts-block__logo .icon-phone {
    font-size: 38px;
  }
}

.contacts-block__logo .icon-open {
  font-size: 25px;
}

@media only screen and (min-width: 768px) {
  .contacts-block__logo .icon-open {
    font-size: 44px;
  }
}

.contacts-block__logo .icon-email {
  font-size: 21px;
}

@media only screen and (min-width: 768px) {
  .contacts-block__logo .icon-email {
    font-size: 32px;
  }
}

.contacts-block__info {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  line-height: 1.44;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}

@media only screen and (min-width: 768px) {
  .contacts-block__info {
    display: block;
    padding-left: 0;
  }
}

.phone-order__text {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  padding-right: 25px;
}

@media only screen and (max-width: 1366px) {
  .phone-order__text {
    display: block;
    margin-bottom: 10px;
  }
}

.phone-order__input {
  display: inline-block;
  border: 3px solid #005ca9;
  border-radius: 0;
  padding: 10px 15px;
  font-size: 18px;
  font-family: OpenSans-Semibold, Arial, sans-serif;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .phone-order__input {
    width: auto;
    margin-bottom: 0;
  }
}

.map {
  width: 100%;
  height: 100%;
  min-height: 200px;
}

@media only screen and (min-width: 768px) {
  .map {
    min-height: 390px;
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .map {
    min-height: 830px;
  }
}

.container {
  position: relative;
  width: 100%;
  padding: 0 20px;
}

@media only screen and (min-width: 1024px) {
  .container {
    padding: 0 2vw;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    padding: 0 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .container {
    padding: 0 13vw;
  }
}

.review-slider {
  padding-top: 40px;
}

@media only screen and (min-width: 1024px) {
  .review-slider {
    padding-top: 0;
  }
}

.review-slider .slick-arrow {
  background-color: transparent;
  font-size: 0;
  width: 30px;
  height: 25px;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  transition: background .3s ease;
}

@media only screen and (min-width: 1024px) {
  .review-slider .slick-arrow {
    top: 30px;
  }
}

.review-slider .slick-arrow.slick-prev {
  background-image: url(../img/arrow-left.svg);
  left: 0;
}

@media only screen and (min-width: 768px) {
  .review-slider .slick-arrow.slick-prev {
    left: auto;
    right: 60px;
  }
}

.review-slider .slick-arrow.slick-prev:hover:not(.slick-disabled) {
  background-image: url(../img/arrow-left-blue.svg);
}

.review-slider .slick-arrow.slick-next {
  background-image: url(../img/arrow-right.svg);
  right: 0;
}

.review-slider .slick-arrow.slick-next:hover:not(.slick-disabled) {
  background-image: url(../img/arrow-right-blue.svg);
}

.review-slider .slick-arrow.slick-disabled {
  opacity: .5;
}

@media only screen and (min-width: 1024px) {
  .review-slide {
    display: flex !important;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1024px) {
  .review-slide__col {
    width: 48%;
  }
}

.review {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .review {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .review + .review {
    margin-top: 60px;
  }
}

.review__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review__img {
  margin-right: 15px;
  width: 60px;
}

@media only screen and (min-width: 1024px) {
  .review__img {
    width: 120px;
  }
}

.review__title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 18px;
}

@media only screen and (min-width: 1024px) {
  .review__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.review__title span {
  display: block;
}

.review__video {
  padding: 10px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  height: 180px;
}

@media only screen and (min-width: 768px) {
  .review__video {
    height: 360px;
    padding: 30px;
  }
}

.review__video-title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .review__video-title {
    font-size: 24px;
  }
}

.customers-slide.slick-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -22.5px;
}

@media only screen and (min-width: 1366px) {
  .customers-slide.slick-slide {
    flex-wrap: nowrap;
  }
}

.customers-slide__item {
  width: 27%;
  filter: grayscale(100%);
  opacity: .5;
  transition: filter .3s ease, opacity .3s ease;
  margin: 0 22.5px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 1366px) {
  .customers-slide__item {
    margin-bottom: 0;
    width: 15%;
  }
}

.customers-slide__item:hover {
  filter: none;
  opacity: 1;
}

.customers-slide__item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customers-slider {
  position: relative;
  padding-top: 25px;
}

.customers-slider .slick-arrow {
  background-color: transparent;
  font-size: 0;
  width: 30px;
  height: 25px;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  transition: background .3s ease;
}

.customers-slider .slick-arrow.slick-prev {
  background-image: url(../img/arrow-left.svg);
  left: 0;
}

@media only screen and (min-width: 768px) {
  .customers-slider .slick-arrow.slick-prev {
    left: auto;
    right: 60px;
  }
}

.customers-slider .slick-arrow.slick-prev:hover:not(.slick-disabled) {
  background-image: url(../img/arrow-left-blue.svg);
}

.customers-slider .slick-arrow.slick-next {
  background-image: url(../img/arrow-right.svg);
  right: 0;
}

.customers-slider .slick-arrow.slick-next:hover:not(.slick-disabled) {
  background-image: url(../img/arrow-right-blue.svg);
}

.customers-slider .slick-arrow.slick-disabled {
  opacity: .5;
}

.offers {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  position: relative;
  margin-top: 20px;
  align-items: stretch;
}

@media only screen and (min-width: 1024px) {
  .offers {
    margin-left: -55px;
    padding-right: 2vw;
  }
}

@media only screen and (min-width: 1600px) {
  .offers {
    padding-right: 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .offers {
    padding-right: 13vw;
  }
}

.offer {
  width: 100%;
  padding: 20px;
  transition: background-color .3s;
}

.offer:hover {
  background-color: rgba(0, 92, 169, 0.5);
}

@media only screen and (min-width: 768px) {
  .offer {
    padding: 40px 30px;
    width: 50%;
  }
}

@media only screen and (min-width: 1366px) {
  .offer {
    padding: 50px 30px;
  }
}

.offer.active .offer__title span.price {
  visibility: visible;
}

.offer.active .offer__btn {
  visibility: visible;
}

.offer__title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  color: #fff;
}

.offer__title span {
  width: 50%;
  display: inline-block;
}

.offer__title span + span {
  text-align: right;
}

.offer__title span.price {
  visibility: hidden;
}

.offer__title_l span {
  width: 100%;
  font-size: 16px;
}

.offer__btn {
  visibility: hidden;
}

.offer__descr {
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}

.result {
  background-color: #005ca9;
  padding: 20px;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .result {
    position: absolute;
    height: 50%;
    bottom: 0;
    left: -100%;
    padding: 40px 50px;
    padding-left: 2vw;
  }
}

@media only screen and (min-width: 1600px) {
  .result {
    padding-left: 8vw;
  }
}

@media only screen and (min-width: 1920px) {
  .result {
    padding-left: 13vw;
  }
}

.result__text {
  font-family: OpenSans-Semibold, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .result__text {
    font-size: 24px;
  }
}

.result__title {
  font-family: OpenSans-ExtraBold, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .result__title {
    font-size: 30px;
    margin-top: 20px;
  }
}

.menu {
  position: absolute;
  left: -100%;
  transition: left .3s ease;
  background-color: #fff;
  top: 0;
  z-index: -1;
  padding: 50px 0 15px;
}

@media only screen and (min-width: 768px) {
  .menu {
    padding: 90px 0 15px;
  }
}

@media only screen and (min-width: 1024px) {
  .menu {
    position: static;
    background-color: transparent;
    padding: 0;
    z-index: 1;
  }
}

.menu__item {
  display: block;
  margin-left: 15px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) {
  .menu__item {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .menu__item {
    display: inline-block;
    margin-left: 0;
    margin-bottom: 0;
  }
  .menu__item + .menu__item {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .menu__item {
    margin-left: 40px;
  }
}

.menu__item a {
  color: #202020;
  font-family: OpenSans-Bold, Arial, sans-serif;
}

@media only screen and (min-width: 1024px) {
  .menu__item a {
    color: #fff;
    transition: color .3s ease;
  }
  .menu__item a:hover, .menu__item a.active {
    color: #005ca9;
  }
}

.menu .btn_header {
  display: block;
  top: 90px;
  right: 0;
  padding: 15px 20px;
  height: auto;
  line-height: 1;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  .menu .btn_header {
    position: absolute;
  }
}

@media only screen and (min-width: 1024px) {
  .menu .btn_header {
    display: none;
  }
}

.menu-btn {
  width: 25px;
  height: 15px;
  position: relative;
  display: block;
  background-color: transparent;
}

@media only screen and (min-width: 768px) {
  .menu-btn {
    height: 25px;
    width: 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .menu-btn {
    display: none;
  }
}

.menu-btn__dash {
  width: 25px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(0);
  transition: .3s ease;
}

.menu-btn__dash:nth-child(1) {
  top: 0;
}

.menu-btn__dash:nth-child(2) {
  top: calc(50% - 1px);
}

@media only screen and (min-width: 768px) {
  .menu-btn__dash:nth-child(2) {
    top: calc(50% - 2.5px);
  }
}

.menu-btn__dash:nth-child(3) {
  bottom: 0;
}

@media only screen and (min-width: 768px) {
  .menu-btn__dash {
    width: 35px;
    height: 5px;
  }
}

.menu-open .header__logo {
  background-image: url(../img/logo.png);
}

.menu-open .btn_tel .icon-phone {
  fill: #005ca9;
}

.menu-open .menu-btn__dash {
  background-color: #202020;
  height: 3px;
  width: 30px;
}

.menu-open .menu-btn__dash:nth-child(1) {
  transform: rotate(45deg);
  top: 55%;
}

.menu-open .menu-btn__dash:nth-child(2) {
  width: 0;
  top: 0;
}

.menu-open .menu-btn__dash:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 25%;
}

@media only screen and (min-width: 768px) {
  .menu-open .menu-btn__dash:nth-child(3) {
    bottom: 35%;
  }
}

.menu-open .menu {
  left: 0;
  width: 100%;
}

.menu-footer {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .menu-footer {
    width: 33.333%;
    text-align: left;
  }
}

.menu-footer__item {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
}

.menu-footer__item + .menu-footer__item {
  margin-left: 15px;
}

@media only screen and (min-width: 1366px) {
  .menu-footer__item + .menu-footer__item {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1366px) {
  .menu-footer__item {
    margin-left: 40px;
  }
}

.menu-footer__item a {
  font-family: OpenSans-Bold, Arial, sans-serif;
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .menu-footer__item a {
    transition: .3s ease;
  }
  .menu-footer__item a:hover, .menu-footer__item a.active {
    color: #005ca9;
  }
}

.range-slider {
  width: 100%;
}

.range-slider.ui-slider-horizontal {
  border: none;
  border-bottom: 3px solid #b7b7b7;
  position: relative;
}

.range-slider.ui-slider-horizontal::after, .range-slider.ui-slider-horizontal::before {
  content: '';
  display: block;
  width: 3px;
  background-color: #005ca9;
  position: absolute;
  height: 20px;
  top: 1px;
}

@media only screen and (min-width: 768px) {
  .range-slider.ui-slider-horizontal::after, .range-slider.ui-slider-horizontal::before {
    top: 3px;
  }
}

.range-slider.ui-slider-horizontal::before {
  left: 0;
}

.range-slider.ui-slider-horizontal::after {
  right: 0;
}

.range-slider.ui-slider-horizontal .ui-slider-handle {
  top: 22px;
  border: none !important;
  background-color: transparent !important;
  color: #202020 !important;
  text-align: center;
  margin-left: -38px;
  width: 80px;
}

.range-slider.ui-slider-horizontal .ui-slider-handle::before {
  content: '';
  display: block;
  width: 22px;
  height: 22px;
  background-color: #005ca9;
  border-radius: 50%;
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .range-slider.ui-slider-horizontal .ui-slider-handle::before {
    top: -20px;
  }
}

.range-slider.ui-slider-horizontal .ui-slider-range {
  height: 3px;
  top: 9px;
  background-color: #005ca9;
}

@media only screen and (min-width: 768px) {
  .range-slider.ui-slider-horizontal .ui-slider-range {
    top: 11px;
  }
}

.animate {
  transition: transform 2s;
}

.animate:before {
  content: '';
  position: absolute;
  background-color: #fff;
  display: block;
  width: 102%;
  height: 100%;
  z-index: 99;
  transition: width 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.animate_to-l {
  transform: translateX(1%);
}

.animate_to-l:before {
  left: -1%;
}

.animate_to-r {
  transform: translateX(-1%);
}

.animate_to-r:before {
  right: -1%;
}

.animate.active {
  transform: translateX(0%);
}

.animate.active:before {
  width: 0%;
}
