.menu {
  position: absolute;
  left: -100%;
  transition: left .3s ease;
  background-color: $white;
  top: 0;
  z-index: -1;
  padding: 50px 0 15px;
  @include rmin(768) {
    padding: 90px 0 15px; }
  @include rmin(1024) {
    position: static;
    background-color: transparent;
    padding: 0;
    z-index: 1; }
  &__item {
    display: block;
    margin-left: 15px;
    margin-bottom: 35px;
    @include rmin(768) {
      margin-left: 30px; }
    @include rmin(1024) {
      display: inline-block;
      margin-left: 0;
      margin-bottom: 0;
      & + & {
        margin-left: 30px; }
      @include rmin(1366) {
        margin-left: 40px; } }
    a {
      color: $black;
      @include os-bold;
      @include rmin(1024) {
        color: $white;
        transition: color .3s ease;
        &:hover,
        &.active {
          color: $accent; } } } }
  .btn_header {
    display: block;
    top: 90px;
    right: 0;
    padding: 15px 20px;
    height: auto;
    line-height: 1;
    margin-bottom: 25px;
    @include rmin(768) {
      position: absolute; }
    @include rmin(1024) {
      display: none; } } }

.menu-btn {
  width: 25px;
  height: 15px;
  position: relative;
  display: block;
  background-color: transparent;
  @include rmin(768) {
    height: 25px;
    width: 35px; }
  @include rmin(1024) {
    display: none; }
  &__dash {
    width: 25px;
    height: 2px;
    background-color: $white;
    position: absolute;
    transform: rotate(0);
    transition: .3s ease;
    &:nth-child(1) {
      top: 0; }
    &:nth-child(2) {
      top: calc(50% - 1px);
      @include rmin(768) {
        top: calc(50% - 2.5px); } }
    &:nth-child(3) {
      bottom: 0; }
    @include rmin(768) {
      width: 35px;
      height: 5px; } } }

.menu-open {
  .header {
    &__logo {
      background-image: url(../img/logo.png); } }
  .btn_tel .icon-phone {
    fill: $accent; }
  .menu-btn {
    &__dash {
      background-color: $black;
      height: 3px;
      width: 30px;
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 55%; }
      &:nth-child(2) {
        width: 0;
        top: 0; }
      &:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 25%;
        @include rmin(768) {
          bottom: 35%; } } } }
  .menu {
    left: 0;
    width: 100%; } }

.menu-footer {
  text-align: center;
  @include rmin(768) {
    width: 33.333%;
    text-align: left; }
  &__item {
    display: inline-block;
    margin-left: 0;
    margin-bottom: 0;
    & + & {
      margin-left: 15px;
      @include rmin(1366) {
        margin-left: 30px; } }
    @include rmin(1366) {
      margin-left: 40px; }
    a {
      @include os-bold;
      color: $white;
      @include rmin(1024) {
        transition: .3s ease;
        &:hover,
        &.active {
          color: $accent; } } } } }
