.offers {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  position: relative;
  margin-top: 20px;
  align-items: stretch;
  @include rmin(1024) {
    margin-left: -55px;
    padding-right: 2vw; }
  @include rmin(1600) {
    padding-right: 8vw; }
  @include rmin(1920) {
    padding-right: 13vw; } }
.offer {
  width: 100%;
  padding: 20px;
  transition: background-color .3s;
  &:hover {
    background-color: rgba($accent, .5); }
  @include rmin(768) {
    padding: 40px 30px;
    width: 50%; }
  @include rmin(1366) {
    padding: 50px 30px; }
  &.active {
    .offer__title {
      span.price {
        visibility: visible; } }
    .offer__btn {
      visibility: visible; } }
  &__title {
    @include os-extra;
    color: #fff;
    span {
      width: 50%;
      display: inline-block;
      + span {
        text-align: right; }
      &.price {
        visibility: hidden; } }
    &_l {
      span {
        width: 100%;
        font-size: 16px; } } }
  &__btn {
    visibility: hidden; }
  &__descr {
    color: #fff;
    font-size: 14px;
    margin-top: 20px; } }
