.container {
  position: relative;
  width: 100%;
  padding: 0 20px;
  @include rmin(1024) {
    padding: 0 2vw; }
  @include rmin(1600) {
    padding: 0 8vw; }
  @include rmin(1920) {
    padding: 0 13vw; } }
