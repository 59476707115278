.text {
  &_comment {
    @include os-semi;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 45px; }

  &_l {
    line-height: 1.5;
    margin-bottom: 30vh;
    @include rmin(768) {
      margin-bottom: 20em; }
    @include rmin(1024) {
      margin-bottom: 2.7em; }
    @include rmin(1680) {
      font-size: 24px; } }

  &_white {
    color: $white; } }
