.main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: url(../img/main-bg.jpg);
  @include cover;
  @include rmin(1024) {
    background-image: none; }
  &__info {
    display: inline-block;
    background-color: rgba(#000, .3);
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    width: 100%;
    @include rmin(1024) {
      width: 50%;
      padding-right: 0;
      padding: 2vw; }
    @include rmin(1680) {
      padding-left: 8vw; }
    @include rmin(1920) {
      padding-left: 13vw; } }
  &__video {
    @include r(1023) {
      width: 100%;
      height: 100px;
      position: absolute;
      left: 0;
      top: 50%; }
    @include rmin(1024) {
      width: 50%;
      height: 100%;
      position: relative; } } }
