.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-dashboard {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-download {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-email {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-gl {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-location {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-open {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-vk {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-yt {
  width: 1em;
  height: 1em;
  fill: initial;
}
