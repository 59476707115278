.video-block {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: none;
  @include rmin(1024) {
    display: block; } }
