.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include clr;
  &_mt {
    @include rmin(1024) {
      margin-top: 25px; } }
  &__item {
    position: relative;
    padding-top: 15px;
    width: 100%;
    float: left;
    text-align: left;
    position: relative;
    &_pad-l {
      padding-top: 50px; }
    @include rmin(768) {
      padding: 15px 10px 0;
      &_pad-l {
        padding-top: 50px; } }
    @include rmin(1024) {
      width: 50%;
      padding-top: 35px;
      &:nth-of-type(2n + 1) {
        padding-left: 0; }
      &:nth-of-type(2n) {
        padding-right: 0; }
      &_wide {
        width: 100%;
        padding-left: 0;
        padding-right: 0; }
      &_no-pt {
        padding-top: 0; }
      &_pad-l {
        padding-top: 60px; } } }
  &__label {
    display: block;
    @include os-semi;
    margin-bottom: 10px;
    font-size: 16px;
    @include rmin(1920) {
      font-size: 18px; } }
  &__input {
    border: 3px solid $black;
    border-radius: 0;
    padding: 10px 15px;
    width: 100%;
    color: $black;
    @include os-semi;
    font-size: 14px;
    @include rmin(768) {
      font-size: 18px; }
    &::placeholder {
      @include os-semi;
      color: #b6b6b6;
      font-size: 14px;
      @include rmin(768) {
        font-size: 18px; } } }
  .ui-selectmenu-menu {
    @include rmin(1024) {
      width: calc(100% - 10px); } } }
