.title {
  @include rs-bold;
  color: $black;
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1em;
  @include rmin(768) {
    font-size: 48px;
    margin-bottom: 50px;
    &_sm-margin {
      margin-bottom: 15px; }
    &_m-margin {
      margin-bottom: 30px; } }
  span {
    font-size: 30px;
    @include rmin(768) {
      font-size: 60px; }
    @include rmin(1024) {
      font-size: 100px; } }
  &_main {
    font-size: 30px;
    color: $white;
    margin-bottom: .7em;
    margin-top: 20vh;
    @include rmin(1024) {
      font-size: 62px;
      margin-top: 2.9em; }
    @include rmin(1680) {
      margin-top: 3.4em; }
    @include rmin(1680) {
      font-size: 72px; } }
  &_grid {
    color: $accent;
    font-size: 18px;
    @include rmin(768) {
      margin-top: 30px;
      font-size: 30px; }
    @include rmin(1366) {
      font-size: 60px; } }
  &_l {
    font-size: 30px;
    @include rmin(768) {
      font-size: 72px; } }
  &_white {
    color: #fff; } }
