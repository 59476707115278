.popup {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100vh;
	background: rgba(#000, .6);
	overflow: auto;
	opacity: 0;
	visibility: hidden;
	z-index: 999;
	transition: opacity .3s ease, visibility .3s ease;
	&.is-open {
		opacity: 1;
		visibility: visible; }
	&__in {
		position: absolute;
		top: 0;
		width: 100%;
		@include rmin(768) {
			@include vcenter; }
		@include rmin(1024) {
			width: auto; } }
	&__header {
		height: 54px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		@include rmin(768) {
			display: none; } }
	&__header-logo {
		background-image: url(../img/logo.png);
		@include contain;
		width: 105px;
		height: 40px; }
	&__body {
		position: relative;
		max-width: 100%;
		background: $white;
		padding: 50px 75px;
		.popup__close {
			top: 0;
			right: 0;
			.icon-close {
				font-size: 30px;
				fill: #000; } } }
	&__close {
		position: absolute;
		padding: 20px;
		right: 0;
		top: 0;
		background-color: transparent;
		z-index: 99;
		.icon-close {
			fill: $black;
			font-size: 18px;
			@include rmin(1024) {
				transition: .3s ease;
				&:hover,
				&:active {
					fill: #df1d23; } } } } }

.popup_call {
	.phone-order {
		&__input {
			font-size: 14px;
			width: 100%;
			margin-bottom: 15px;
			@include rmin(768) {
				font-size: 18px;
				width: auto;
				margin-bottom: 0; } }
		&__text {
			display: block;
			margin-bottom: 10px; }
		.btn_phone-order {
			width: 100%;
			@include rmin(768) {
				width: auto; } } }
	.popup {
		&__body {
			padding: 10px 30px 30px;
			@include rmin(768) {
				padding: 50px 75px; }
			@include rmin(1024) {
				padding: 35px; }
			@include rmin(1366) {
				padding: 50px 75px; } }
		&__close {
			display: block;
			top: 0;
			right: 0;
			@include rmin(768) {
				padding: 20px;
				.icon-close {
					font-size: 30px;
					fill: #000; } }
			@include rmin(1024) {
				.icon-close {
					font-size: 18px; } }
			.icon-close {
				fill: $accent; } } } }

.popup_application {
	.popup {
		&__body {
			padding: 0 30px 50px;
			@include rmin(768) {
				padding: 50px; }
			@include rmin(1024) {
				padding: 50px 100px; } }
		&__close {
			padding: 10px;
			top: 10px;
			right: 10px;
			@include rmin(768) {
				padding: 20px;
				top: 0;
				right: 0; } } }
	.application-bg {
		top: 0; }
	.application {
		max-width: 100%;
		width: 100%;
		padding: 0;
		@include rmin(1024) {
			width: 740px; }
		.title {
			font-size: 30px;
			@include rmin(768) {
				font-size: 36px;
				text-align: left; }
			&_m-margin {
				margin-bottom: 0; } } }
	.form {
		&__label {
			font-size: 14px; }
		&__input {
			font-size: 14px;
			@include rmin(768) {
				font-size: 18px; }
			&::placeholder {
				font-size: 14px;
				@include rmin(768) {
					font-size: 18px; } } }
		&__item {
			padding: 0;
			padding-top: 25px;
			width: 100%;
			@include rmin(768) {
				padding: 25px 10px 0;
				width: 50%; }
			&_wide {
				@include rmin(768) {
					width: 100%; } } } }
	.ui-selectmenu-button {
		font-size: 14px;
		@include rmin(768) {
			font-size: 18px; } } }

.popup_video {
	display: none;
	@include rmin(1024) {
		display: block; }
	.popup {
		&__body {
			width: 85vw;
			height: calc(85vw / 1.5);
			padding: 50px;
			@include rmin(1366) {
				width: 64vw;
				height: 79vh;
				padding: 85px 90px; } } }

	iframe {
		width: 100%;
		height: 100%; } }
